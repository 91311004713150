
  .info-section {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-right: 0%;
    margin-top: 30px;
  }
  
  .info-box {
    flex: 1;
    padding: 20px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
  }
  
  .info-box:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .about-us-box {
    background-color:lightgray;
    margin-right: 0;
  }
  
  .contact-us-box {
    background-color:black ;
    margin-left: 0;
    color: white;
  }
  
  .info-box h2 {
    margin-bottom: 10px;
  }
  
  .info-box p {
    margin: 0;
  }



  /* General Footer Styling */
.footer {
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #ddd;
  font-family: 'Century', sans-serif;
  margin-right: 0%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-left, .footer-center, .footer-right {
  flex: 1;
  padding: 10px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-center {
  text-align: center;
}

.footer-right {
  text-align: right;
}

.footer-social a {
  margin: 0 10px;
}

.footer-social img {
  width: 200%;
  height: 200%;
}

.footer-policies, .footer-madeby {
  margin-top: 10px;
}

.footer-policies a, .footer-madeby a {
  color: #8D6E63;
  text-decoration: none;
}

.footer-policies a:hover, .footer-madeby a:hover {
  text-decoration: underline;
}

/* Home Container Styling */
.home-container {
  display: flex;
  justify-content: center;
  background-color: white;
  padding-top: 50px;
  font-family: 'Century', sans-serif;
}

.section {
  flex: 1;
  padding: 20px;
  background-color: #ccc; /* Change as needed */
  text-align: center;
  position: relative;
}

.section:nth-child(2) {
  background-color: #000;
  color: #fff;
}

.section:nth-child(3) {
  background-color: #555;
  color: #fff;
}

.section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.section-content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.arrow-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: none;
  border: none;
  font-size: 24px;
  color: inherit;
  cursor: pointer;
}

.arrow-button:hover {
  opacity: 0.8;
}

/* Adjust address styling */
.footer address {
  font-style: normal; /* Override italic font style */
}
/* Responsive Footer Styling */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center; /* Center text for all sections */
      max-width: 1200px; /* Optional max-width for better centering on larger screens */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
  }

  .footer-left, .footer-center, .footer-right {
      text-align: center; /* Center text for each section */
      min-width: 200px; /* Ensures sections don't get too narrow */
  }
  /* .footer-left {
      margin-left: 10%;
  } */

  .footer-social {
      margin-top: 10px;
  }

  .footer-social img {
      width: 24px; /* Adjust as needed */
      height: 24px; /* Adjust as needed */
  }
}
.section-content p{
  color:black;
  text-decoration: none ;
}
.section-content1 p{
  color:white;
  text-decoration: none;
}
a{
  text-decoration: none;
}