* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow-x: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-right: 0;
}

.content {
  flex: 1;
}

.home-page {
  width: 100%;
  height: 100%;
}

.header {
  position: relative;
}

.slick-slider {
  width: 100%;
  height: auto;
}

.slick-slider img {
  width: 100%;
  height: 100vh; /* Ensure images cover the viewport height */
  object-fit: cover;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: left;
  z-index: 2;
}

.header-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.header-content p {
  font-size: 1.25rem;
}

.brands {
  padding: 2rem 1rem;
  text-align: center;
}

.brand-logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.brand {
  text-align: center;
  margin: 1rem;
}

.brand img {
  max-width: 100px;
  margin-bottom: 0.5rem;
}

.brand p {
  font-size: 1rem;
  color: #555;
}

.collections-section {
  text-align: center;
  padding: 20px;
}

.collections-title {
  font-size: 36px;
  font-weight: bold;
  color: #B71C1C;
}

.collections-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.collection-item {
  text-decoration: none;
  overflow: hidden;
}

.collection-image {
  position: relative;
  width: 600px;
  height: 400px;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.collection-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}

.collection-item:hover .collection-image::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.collection-item:hover .collection-image {
  transform: scale(1.05);
}

.collection-label {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.curtains {
  background-image: url('../Assets/SYDNEY-01.jpg');
}

.upholstery {
  background-image: url('../Assets/VIENNA-02.jpg');
}

.curtains-link {
  animation: slideInFromLeft 0.5s ease forwards;
}

.upholstery-link {
  animation: slideInFromRight 0.5s ease forwards;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.events-section {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 10px;
}

.events-title {
  font-size: 2em;
  margin-bottom: 10px;
}

.events-subtitle {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555;
}

.events-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.event-item {
  position: relative;
  width: 30%;
  overflow: hidden;
}

.event-item img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  transition: transform 0.3s, box-shadow 0.3s;
}

.event-item:hover img {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.collections-section {
  text-align: center;
  margin: 40px 0;
}


.collections-description {
  font-size: 1.2em;
  color: #555;
  margin: 10px 0;
}

.collection-label{
color: collection-label;
}
.carousel-container {
  width: 100%; /* Adjust the width as necessary */
 margin: 0 auto;
 margin-top: 0px;
 margin-bottom: 10px; 
 height: 100vh;/* Center the carousel */
}

.carousel-item img {
 height: 100vh; /* Adjust the height as necessary */
 object-fit: cover;
}
.carousel-container {
 margin-bottom: 40px;
 background: rgba(0, 0, 0, 0.5); /* Optional: Add a background for better visibility */

}
@media (max-width: 576px) {
  .carousel-container {
    width: 100%; /* Adjust the width as necessary */
   margin: 0 auto;
   margin-top: 0px;
   margin-bottom: 10px; 
   height: 75vh;/* Center the carousel */
  }
  
  .carousel-item img {
   height: 75vh; /* Adjust the height as necessary */
   object-fit: cover;
  }
  .header-content {
    text-align: center;
  }

  .header-content h1 {
    font-size: 1.2rem;
  }

  .header-content p {
    font-size: 0.8rem;
  }

  .collections-title {
    font-size: 24px;
  }

  .collections-container {
    flex-direction: column;
    gap: 50px;
    margin: 0px;
  }

  .collection-image {
    height: 400px;
    margin: 0px;

  }

  .collection-label {
    font-size: 18px;
  }

  .events-title {
    font-size: 1.5rem;
  }

  .events-subtitle {
    font-size: 1rem;
  }

  .events-container {
    flex-direction: column;
    gap: 20px;
    margin-left:30px;
  }

  .event-item {
    width: 90%;
  }

  .event-item img {
    height: 200px;
  }

  .brand-logos {
    flex-direction: column;
    gap: 10px;
  }

  .brand img {
    max-width: 80px;
  }
}
