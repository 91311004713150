/* Custom CSS for nested dropdown */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
.bg-tertiary {
  background-color: lightgrey; /* You can also use the hex code #D3D3D3 or rgb(211, 211, 211) */
}
.navbar-nav .nav-link {
  color: white; /* Default color */
}

.navbar-nav .nav-link:hover {
  color: #B71C1C; /* Red color on hover */
}
